import React from "react"

import wave from '../images/wave-1920x1080.jpg'
const IndexPage = () => (
    <div className="main-area-wrapper" 
        style={{ backgroundImage: `url(${wave})`, 
        width: '100%',  height: '100vh', margin: 'auto', 
        overflow: 'hidden', position: 'fixed'}} 
        >
        <div className="main-area center-text" >

            <div className="display-table">
            <div className="display-table-cell" style={{margin: '5px'}}>

                    <h1 className="title" ><b>Coming Soon</b></h1>
                    <p className="desc" style={{ color: 'white' }}  >Our website is currently undergoing development. Stay tuned.</p>

                </div>
            </div>
        </div>
    </div>
)

export default IndexPage
